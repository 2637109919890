.code {
  --scroll-track: rgba(255, 255, 255, 0.16);
  --scroll-thumb: rgba(255, 255, 255, 0.32);
  --scroll-thumb-hover: rgba(255, 255, 255, 0.56);

  --code-bg: transparent;
  --code-header-bg: rgba(255, 255, 255, 0.08);
  --code-tab: rgba(255, 255, 255, 0.56);
  --code-tab-active: #fff;
  --code-gutter: rgba(255, 255, 255, 0.24);
  --code-active-line: rgba(255, 255, 255, 0.04);
  --code-selection: rgba(255, 255, 255, 0.2);
  --code-address: rgba(255, 255, 255, 0.2);
  --code-variable: #efa2c5;
  --code-string: #69b99c;

  background-color: var(--code-bg);

  :global {
    .ace_editor {
      background-color: transparent;
      font: 12px/16px 'PT Mono', 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
      letter-spacing: 0.6px;
    }

    .ace_scrollbar-v,
    .ace_scrollbar-h {
      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--scroll-track);
        border: none;
        box-shadow: none;
        outline: none;
        padding: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb);
        border: 1px solid transparent;
        border-radius: 0;
        cursor: grab;
        width: 6px;
        &:hover {
          background: var(--scroll-thumb-hover);
          background-color: var(--scroll-thumb-hover);
        }
      }
    }

    .ace-tomorrow-night-blue,
    .ace-tomorrow {
      .ace_gutter {
        background-color: transparent;
        color: var(--code-gutter);
      }

      .ace_gutter-active-line {
        background: var(--code-active-line);
      }

      .ace_marker-layer {
        .ace_selection {
          background: var(--code-selection);
        }
        .ace_active-line {
          background: var(--code-active-line);
        }
      }
      .ace_string {
        color: var(--code-string);
      }
    }

    .ace_address_marker {
      position: absolute;
      background: var(--code-address);
    }
  }
}

.header {
  align-items: center;
  background-color: var(--code-header-bg);
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  min-height: 36px;
}

.title {
  // @include h-xxs;
  padding: 8px;
  color: var(--code-tab);
}

.tabs {
  display: flex;

  .tab {
    padding: 8px 12px 7px 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    color: var(--code-tab);

    &.active {
      color: var(--code-tab-active);
      border-bottom: 1px solid var(--primary);
    }
  }
}

.bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
}

.icon {
  // @include btn-clean;
  color: white;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  path {
    transition: 0.2s ease;
    color: white;
  }
}

.popup {
  position: relative;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.2s ease, transform 0.2s ease;

  &.active {
    opacity: 1;
    transform: none;
  }

  .code {
    position: relative;
    z-index: 2;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  &::before {
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
}
